import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Sidebar from "../../../Items/Sidebar";
import useVerify from "../../../useVerify";
import { FiUserCheck } from "react-icons/fi";

export default function DetailQuiz() {
  useVerify("admin");

  const { quizId } = useParams();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const [detailQuiz, setDetailQuiz] = useState({ koloms: [] });

  const [inputValues, setInputValues] = useState([{ kolom: "" }]);
  const [judulQuiz, setJudulQuiz] = useState("");

  useEffect(() => {
    fetchDetailQuiz();
    // eslint-disable-next-line
  }, []);

  function fetchDetailQuiz() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/quiz/${quizId}`)
      .then((response) => response.json())
      .then((data) => {
        setDetailQuiz(data);
        setJudulQuiz(data.quizName);
        setInputValues(data.koloms);
      });
  }

  const handleDeleteTes = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/quiz/${quizId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });

    setShowModal(false);

    return navigate("/listquiz");
  };

  const handleJudulChange = (event) => {
    setJudulQuiz(event.target.value);
  };

  const handleInputChange = (event, index) => {
    const koloms = JSON.parse(JSON.stringify(inputValues));
    koloms[index].kolom = event.target.value;
    setInputValues(koloms);
  };

  const submitUpdateQuiz = async () => {
    await updateQuizName();

    // update kolom
    const promises = inputValues.map((input) =>
      updateKolomByKolomId(input.kolomId, input.kolom)
    );
    await Promise.all(promises);

    // kembali ke tampilan awal
    setShowModalUpdate(false);
    fetchDetailQuiz();
  };

  async function updateQuizName() {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/quiz/${quizId}`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ quizName: judulQuiz }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        console.log(data.message);
      });
  }

  async function updateKolomByKolomId(kolomId, kolom) {
    kolom = kolom.split(",").map((item) => item.trim());

    await fetch(`${process.env.REACT_APP_BASE_URL}/api/kolom/${kolomId}`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ kolom }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        console.log(data.message);
      });
  }

  return (
    <div className="w-full flex flex-row h-screen bg-slate-100 overflow-auto">
      <div className="w-1/5">
        <Sidebar />
      </div>
      <div className="w-4/5 bg-slate-100 p-20">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">
          <span className="text-slate-800">Detail Quiz </span>
          {detailQuiz.quizName}
        </h1>
        <div className="flex justify-between mt-12 mx-2">
          <div className="flex gap-2">
            <button
              onClick={() => setShowModal(true)}
              className="py-2 px-4 rounded text-xs shadow-md bg-red-500 text-slate-50 font-semibold"
            >
              Hapus Quiz
            </button>
            <button
              onClick={() => setShowModalUpdate(true)}
              className="py-2 px-4 rounded text-xs shadow-md bg-blue-500 text-slate-50 font-semibold"
            >
              Update Quiz
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="flex items-center px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">
                            Kolom
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">Soal</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {detailQuiz.koloms.map((kolom, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            Kolom {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {kolom.kolom}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {showModal ? (
                    <>
                      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                              <div className="flex flex-row justify-center items-center">
                                <FiUserCheck className="text-2xl mr-3" />
                                <h3 className=" text-3xl font-semibold">
                                  Hapus Tes {detailQuiz.quizName}
                                </h3>
                              </div>
                              <button
                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                              >
                                ×
                              </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                              <div className="my-2 text-slate-800 text-lg leading-relaxed">
                                <p className=" pb-3 text-md">
                                  Tes yang sudah dihapus tidak bisa di restore
                                  kembali, apakah yakin ingin menghapus tes ?
                                </p>
                              </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                              <button
                                className="border-2 
                                        border-red-600 text-red-600 
                                        hover:text-slate-100 hover:bg-red-600 hover:border-transparent
                                        transition duration-500 ease-in-out 
                                        font-bold uppercase text-sm px-4 py-2 rounded shadow  mr-1 mb-1"
                                onClick={handleDeleteTes}
                              >
                                Ya, saya yakin
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                  ) : null}

                  {showModalUpdate ? (
                    <>
                      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                          {/*content*/}
                          <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-w-[400px] bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                              <h3 className="text-3xl font-semibold">
                                Update Quiz
                              </h3>
                              <button
                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModalUpdate(false)}
                              >
                                ×
                              </button>
                            </div>
                            {/*body*/}
                            <div className="relative p-6 flex-auto">
                              <form>
                                <div className="mb-6">
                                  <label
                                    htmlFor="judul"
                                    className="block mb-2 text-sm font-semibold text-gray-700 "
                                  >
                                    Judul Quiz
                                  </label>
                                  <input
                                    type="text"
                                    id="judul"
                                    name="judul"
                                    autoComplete="judul"
                                    className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="masukan judul quiz"
                                    value={judulQuiz}
                                    onChange={handleJudulChange}
                                    required
                                  />
                                </div>
                                {inputValues.map((input, index) => (
                                  <div key={index} className="mb-6">
                                    <label
                                      htmlFor="k1"
                                      className="block mb-2 text-sm font-medium text-gray-700 "
                                    >
                                      Kolom {index + 1}
                                    </label>
                                    <input
                                      type="text"
                                      id="k1"
                                      name="k1"
                                      autoComplete="k1"
                                      className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                      placeholder="isi kolom 1, ex: 1, 4, 2, 5, 8"
                                      value={input.kolom}
                                      onChange={(event) =>
                                        handleInputChange(event, index)
                                      }
                                      required
                                    />
                                  </div>
                                ))}
                              </form>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                              <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={() => setShowModalUpdate(false)}
                              >
                                Close
                              </button>
                              <button
                                className="bg-slate-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                onClick={submitUpdateQuiz}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
