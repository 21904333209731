import React from "react";

function PanduanDinamis() {
    return (
        <div>
          <div className="mt-6">
            <p className="text-sm text-slate-800">
              Membuat tes hanya mengisi karakter yang ingin ditampilkan. setiap kolom
              akan dibuat otomatis sesuai banyak kolom yang diinginkan. setiap kolom
              akan memiliki soalnya sendiri dan juga memiliki waktu pengerjaan
              1 menit. ada 3 bagian yang perlu diisi diantaranya bagian judul, banyak kolom dan
              bagian isi karakter :
            </p>
            <li className="text-sm text-slate-800">
              Judul : isi judul quiz (contoh : Angka Hilang)
            </li>
            <li className="text-sm text-slate-800">
              Banyak Kolom : Banyak kolom dalam tes (contoh : 3, artinya tes yang dibuat akan memiliki 3 kolom)
            </li>
            <li className="text-sm text-slate-800">
              Karakter : isi karakter apa saja yang ingin ditampilkan kolom (contoh : 1, 3, 4, 6, 7, A, B, C, D, E)
              <span className="text-green-600 text-xs font-semibold">
                {" "}
                *jumlah karakter dibebaskan
              </span>
            </li>
          </div>
          <div className="border rounded mt-4 p-6 text-xs text-slate-800">
            <p className="mb-1">
              * jika kolom diisi "1, 3, 4, 6, 7, A, B, C, D, E" maka secara otomatis kolom akan
              terbentuk dengan memilih karakter random dan memasukkan kedalam tabel seperti :
            </p>
            <p>-----------------</p>
            <p>| 1 | A | 7 | 3 | C |</p>
            <p>-----------------</p>
            <p>| A | B | C | D | E |</p>
            <p>-----------------</p>
            <p></p>
          </div>
        </div>
    );
}

export default PanduanDinamis