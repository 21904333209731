import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import Sidebar from "../../../Items/Sidebar";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import useVerify from "../../../useVerify";
import moment from "moment";
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from "xlsx";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

function HistoryPage() {
  useVerify("user");

  const { hasilId } = useParams();

  const [data, setData] = useState({
    labels: ["Kolom 1", "Kolom 2", "Kolom 3", "Kolom 4", "Kolom 5", "Kolom 6"],
    datasets: [
      {
        label: "Terjawab",
        data: [31, 14, 26, 23, 25, 24],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#FF914D",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Benar",
        data: [25, 12, 23, 18, 19, 20],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "green",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Salah",
        data: [6, 2, 3, 5, 6, 4],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "red",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
    ],
  });

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        min: 0,
        max: 50,
      },
    },
  };

  
  useEffect(() => {
    fetchDetailHasilByHasilId(hasilId);
  }, [hasilId]);

  
  const [hasil, setHasil] = useState({ kolomAnswer: [] });
  useEffect(() => {
    if (hasil) {
      // Buat array labels sesuai dengan panjang array kolomAnswer
      const labels = hasil.kolomAnswer.map(
        (answer, index) => `Kolom ${index + 1}`
      );

      const dataTerjawab = hasil.kolomAnswer.map(
        (answer) => answer.totalJawaban
      );

      const dataBenar = hasil.kolomAnswer.map((answer) => answer.benar);
      
      const dataSalah = hasil.kolomAnswer.map((answer) => answer.salah);
      
      setData((prevData) => ({
        labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataTerjawab,
          },
          {
            ...prevData.datasets[1],
            data: dataBenar,
          },
          {
            ...prevData.datasets[2],
            data: dataSalah,
          },
        ],
      }));
    }
  }, [hasil]);
  
  const handleOnExport = (data) => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, hasil.namaLengkap);
    XLSX.writeFile(wb, `${hasil.namaLengkap}_${hasil.namaTest}_${hasil.createdAt}.xlsx`);
  };

  
  async function fetchDetailHasilByHasilId(hasilId) {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/hasil/${hasilId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
      });
    }

  async function exportDataToExcel(hasilId) {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/hasil/${hasilId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
        const datahasil = data;
        console.log(datahasil.kolomAnswer)
        handleOnExport(datahasil.kolomAnswer)
      });
  }

  return (
    <div className="w-full flex flex-row h-screen">
      <div className="w-1/5">
        <Sidebar/>
      </div>
      <div className="w-4/5 bg-slate-100 p-20 overflow-auto">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">
          Nilai Tes <span className="text-black">{hasil.namaLengkap}</span>
        </h1>
        <div className="mt-12">
          <div className="w-full flex flex-col">
            <div className="w-full border p-4">
              <h3 className="text-2xl font-bold text-slate-900">
                {hasil.namaTest}
              </h3>
              <div className="my-4 flex flex-row items-center">
                <BiTimeFive className="mr-1"></BiTimeFive>
                <p className="text-xs md:text-sm">
                  {moment(hasil.createdAt).format("DD MMMM YYYY")}
                </p>
              </div>
              <div className="w-[500px] h-fit bg-gradient-to-br from-slate-300 to-blue-200 rounded-xl shadow-xl p-4">
                <Line data={data} options={options}></Line>
              </div>
            </div>
            <div className="mt-12 w-full inline-block align-middle h-screen overflow-auto">
              <div className="">
                <button
                  onClick={() => exportDataToExcel(hasil.hasilId)}
                  className="ml-2 my-4 text-xs py-2 px-4 bg-green-600 hover:bg-green-800 rounded text-white flex flex-row items-center justify-center transition duration-500 ease-in-out"
                >
                  <RiFileExcel2Line className="mr-1 text-xl"></RiFileExcel2Line>
                  Data Keseluruhan
                </button>
                <table className="min-w-full divide-y divide-slate-600">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        Kolom
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-xs font-bold text-center text-gray-500 uppercase"
                      >
                        Tabel
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-xs font-bold text-center text-gray-500 uppercase"
                      >
                        Total Terjawab
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-xs font-bold text-center text-gray-500 uppercase"
                      >
                        Benar
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-xs font-bold text-center text-gray-500 uppercase"
                      >
                        Salah
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {hasil.kolomAnswer.map((kolom, index) => (
                      <tr key={kolom.kolomAnswerId}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 uppercase whitespace-nowrap">
                          {kolom.kolom}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          <span>{kolom.totalJawaban}</span> / 50
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {kolom.benar}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {kolom.salah}
                        </td>
          
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
