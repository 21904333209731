import { Route, Routes } from "react-router-dom";
import HistoryPage from "./components/hasil_tes/historyPage";
import HistoryList from "./components/hasil_tes/HistoryList";
import QuizPage from "./components/quizPage";
import ResultPage from "./components/resultPage";
import Login from "./components/login_register/login";
import Register from "./components/login_register/register";
import Home from "./components/Home";
import List from "./components/List";
import Admin from "./components/admin/Index";
import UserAktif from "./components/admin/user/UserAktif";
import AktivasiUser from "./components/admin/user/AktivasiUser";
import BuatQuiz from "./components/admin/quiz/BuatQuiz";
import CreateQuiz from "./components/admin/quiz/CreateQuiz";
import CountdownPage from "./components/countdownPage";
import DMakeQuiz from "./components/admin/quiz/QuizStatis";
import SMakeQuiz from "./components/admin/quiz/QuizDinamis";
import ListQuiz from "./components/admin/quiz/list/Index";
import DetailQuiz from "./components/admin/quiz/list/DetailQuiz";
import NilaiSiswa from "./components/admin/hasil/nilaiSiswa/index";
import HistoryListSemua from "./components/hasil_tes/HistoryListSemua";
import HistoryListPerTes from "./components/hasil_tes/HistoryListPerTes";
import HistoryListPerTesDetail from "./components/hasil_tes/HistoryListPerTesDetail";
import DetailNilaiAdmin from "./components/admin/hasil/nilaiSiswa/detailNilai";
import AMakeQuiz from "./components/admin/quiz/QuizAuto";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/list" element={<List />} />
      <Route path="/countdown" element={<CountdownPage />} />
      <Route path="/quiz" element={<QuizPage />} />
      <Route path="/result" element={<ResultPage />} />
      <Route path="/history/:hasilId" element={<HistoryPage />} />
      <Route path="/historylist" element={<HistoryList />} />
      <Route path="/historylistsemua" element={<HistoryListSemua />} />
      <Route path="/historylistpertes" element={<HistoryListPerTes />} />
      <Route
        path="/historylistpertesdetail"
        element={<HistoryListPerTesDetail />}
      />

      {/* ADMIN */}
      <Route path="/admin" element={<Admin />} />
      <Route path="/useraktif" element={<UserAktif />} />
      <Route path="/aktivasiuser" element={<AktivasiUser />} />
      <Route path="/buatquiz" element={<BuatQuiz />} />
      <Route path="/listquiz" element={<ListQuiz />} />
      <Route path="/createquiz" element={<CreateQuiz />} />
      <Route path="/createstatisquiz" element={<DMakeQuiz />} />
      <Route path="/createdinamisquiz" element={<SMakeQuiz />} />
      <Route path="/createautoquiz" element={<AMakeQuiz />} />
      <Route path="/detailquiz/:quizId" element={<DetailQuiz />} />
      <Route path="/nilaisiswa" element={<NilaiSiswa />} />
      <Route path="/detailnilaiadmin/:hasilId" element={<DetailNilaiAdmin />} />
    </Routes>
  );
}

export default App;
