import React, { useState } from "react";
import Sidebar from "../../Items/Sidebar";
import PanduanDinamis from "./panduan/PanduanDinamis";
import { useNavigate } from "react-router-dom";
import useVerify from "../../useVerify";

const QuizDinamis = () => {
  useVerify("admin");

  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [judul, setJudul] = useState("");

  const handleJudulChange = (event) => {
    setJudul(event.target.value);
  };

  const [inputValues, setInputValues] = useState([{ value: "" }]);

  const handleInputChange = (event, index) => {
    const values = [...inputValues];
    values[index].value = event.target.value;
    setInputValues(values);
  };

  const handleAddInput = () => {
    setInputValues([...inputValues, { value: "" }]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${process.env.REACT_APP_BASE_URL}/api/add_quiz`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ quizName: judul, createdBy: 9 }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        console.log(data.message);
        inputValues.forEach((input) => {
          addKolomToApi(data.quizId, input.value);
          navigate("/listquiz");
        });
      });
  };

  function addKolomToApi(quizId, kolom) {
    kolom = kolom.split(",").map((item) => item.trim());

    fetch(`${process.env.REACT_APP_BASE_URL}/api/add_kolom`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ quizId, kolom }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        console.log(data.message);
      });
  }

  return (
    <div className="flex h-screen p-0 overflow-auto">
      <div className="w-1/5">
        <Sidebar />
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-4/5 bg-slate-100 p-20 overflow-auto"
      >
        <h1 className="text-5xl font-extrabold text-[#FF914D]">
          Buat Tes Dinamis
        </h1>
        <PanduanDinamis />

        <div className="mt-5">
          <div className="mb-6">
            <hr />
            <h3 className="text-2xl font-semibold mt-4 mb-6 text-gray-900">
              Isi Judul
            </h3>
            <label
              htmlFor="judul"
              className="block mb-2 text-sm font-semibold text-gray-700 "
            >
              Judul Quiz
            </label>
            <input
              type="text"
              id="judul"
              name="judul"
              value={judul}
              onChange={handleJudulChange}
              autoComplete="judul"
              className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="masukan judul quiz"
              required
            />
          </div>
          <hr />
          <h3 className="text-2xl font-semibold mt-4 mb-6 text-gray-900">
            Isi Kolom
          </h3>
          {inputValues.map((input, index) => (
            <div key={index} className="mb-6">
              <label
                htmlFor="k1"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom {index + 1}
              </label>
              <input
                type="text"
                id="k1"
                name="k1"
                value={input.value}
                onChange={(event) => handleInputChange(event, index)}
                autoComplete="k1"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 1, ex: 1, 4, 2, 5, 8"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
          ))}

          <div className="flex flex-col gap-2">
            <div
              onClick={handleAddInput}
              className="block border border-slate-800 text-slate-800 hover:text-white bg-transparent hover:bg-slate-800 
              font-semibold rounded-lg text-sm w-full px-5 py-2.5 text-center transition ease-in-out duration-500 cursor-pointer"
            >
              Tambah
            </div>
            <div
              onClick={() => setShowModal(true)}
              className="block border border-slate-800 text-slate-800 hover:text-white bg-transparent hover:bg-slate-800 
              font-semibold rounded-lg text-sm w-full px-5 py-2.5 text-center transition ease-in-out duration-500 cursor-pointer"
            >
              Buat Soal
            </div>
          </div>

          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        Konfirmasi Pembuatan Quiz
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        ×
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <div className="my-2 text-slate-500 text-lg leading-relaxed">
                        <p className=" pb-3">
                          Judul :{" "}
                          <span className="font-semibold uppercase">
                            {judul}
                          </span>
                        </p>

                        <table className="table-auto text-left text-sm">
                          <thead className="border-b">
                            <tr>
                              <th className="pr-12">Kolom</th>
                              <th>Soal Kolom</th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputValues.map((input, index) => (
                              <tr key={index}>
                                <td>Kolom {index + 1}</td>
                                <td>{input.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button
                        className="bg-slate-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Buat Soal
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default QuizDinamis;
