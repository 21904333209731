import React from "react";
import Sidebar from "../Items/Sidebar";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import useVerify from "../useVerify";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);
export default function Index() {
  useVerify("admin");

  const data = {
    labels: ["Kolom 1", "Kolom 2", "Kolom 3", "Kolom 4", "Kolom 5", "Kolom 6"],
    datasets: [
      {
        label: "Terjawab",
        data: [31, 14, 26, 23, 25, 24],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#FF914D",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Benar",
        data: [25, 12, 23, 18, 19, 20],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "green",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Salah",
        data: [6, 2, 3, 5, 6, 4],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "red",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        min: 0,
        max: 50,
      },
    },
  };

  return (
    <div className="w-full flex flex-row">
      <div className="w-1/5">
        <Sidebar/>
      </div>
      <div className="w-4/5 bg-slate-100 p-20">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">Dasboard</h1>
        <div className="mt-12">
          <div className="w-[500px] h-fit bg-gradient-to-br from-slate-300 to-blue-200 rounded-xl shadow-xl p-4">
            <Line data={data} options={options}></Line>
          </div>
        </div>
      </div>
    </div>
  );
}
