import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../Items/Navbar";
import Title from "./items/Title";
import { AiFillEye } from "react-icons/ai";
import Moment from "moment";
import useVerify from "../useVerify";

function HistoryListSemua() {
  useVerify("user");

  const [hasil, setHasil] = useState([]);

  const accountCookie = Cookies.get("account");
  const navigate = useNavigate();

  useEffect(() => {
    if (accountCookie === undefined) {
      return navigate("/login");
    }

    // get account data
    const accountTmp = JSON.parse(accountCookie);

    // Fetch data from API
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/hasils/userid/${accountTmp.userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
      });

    // eslint-disable-next-line
  }, [accountCookie]);

  return (
    <div className="w-full h-screen bg-slate-300">
      <Navbar></Navbar>
      <div className="w-full flex flex-col items-center bg-slate-300">
        <div className="w-5/6 md:w-2/3 flex flex-col justify-center items-center ">
          <Title />
          <hr />
          <div className="mt-12 w-full inline-block align-middle h-screen ">
            <div className="rounded-lg h-3/5 overflow-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-slate-100 text-gray-800 uppercase">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left"
                    >
                      <span className="inline-flex items-center">Tes</span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left"
                    >
                      <span className="inline-flex items-center">
                        Total Skor
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-center"
                    >
                      Waktu Pengerjaan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-center"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {hasil.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-blue-600 uppercase whitespace-nowrap">
                        <Link to={`/history/${item.hasilId}`}>
                          <button>{item.namaTest}</button>
                        </Link>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {item.totalCorrectHasil} / <span>500</span>
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {Moment(item.createdAt).format("DD MMMM YYYY")}
                      </td>
                      <td className=" text-xl flex justify-center items-center py-4 font-medium text-center whitespace-nowrap">
                        <Link to={`/history/${item.hasilId}`}>
                          <button>
                            <AiFillEye className="text-blue-500 hover:text-blue-800" />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryListSemua;
