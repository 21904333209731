import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Items/Navbar";
import useVerify from "./useVerify";
import { MdArrowBackIos } from "react-icons/md";
import { AiOutlineSearch, AiFillLock } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

function List() {
  useVerify("user");

  const navigate = useNavigate();
  const [quizs, setQuizs] = useState([]);

  const [query, setQuery] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState("");
  const [quizId, setQuizId] = useState("");

  useEffect(() => {
    fetchAllQuizs(query);

    // eslint-disable-next-line
  }, []);

  async function fetchAllQuizs(key) {
    let uri = "";
    if (key === "") uri = `${process.env.REACT_APP_BASE_URL}/api/quizs/active`;
    else uri = `${process.env.REACT_APP_BASE_URL}/api/quizs/active/${key}`;

    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        setQuizs(data);
      });
  }

  const handleSearch = (event) => {
    setQuery(event.target.value);
    fetchAllQuizs(event.target.value);
  };

  function handleNextPage(quizId) {
    navigate("/countdown", {
      state: { quizId },
    });
  }

  function handleModalPasswordToNextPage(quizId, index) {
    setShowModal(true);
    setCorrectPassword(quizs[index].password);
    setQuizId(quizId);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function handleSubmitPassword() {
    if (password === correctPassword) {
      handleNextPage(quizId);
    } else {
      notify();
    }
  }

  const notify = () => {
    toast.error("Password salah!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div className="w-full h-screen bg-slate-300">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Navbar />

      <div className="w-full h-full flex justify-center items-center mt-12 bg-slate-300">
        <div className="w-2/3 h-full mb-20 mt-12 bg-slate-300">
          <div className="flex flex-row justify-start items-center">
            <button onClick={() => navigate(-1)} className="text-black/90">
              <MdArrowBackIos className="text-4xl"></MdArrowBackIos>
            </button>
            <h1 className="text-4xl font-extrabold mb-1 text-black/90">
              Pilih Tes
            </h1>
          </div>

          <form className="mt-8">
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div className="relative">
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 "
                placeholder="Cari Tes ..."
                value={query}
                onChange={handleSearch}
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-[#FF914D] hover:bg-[#e8742b] focus:ring-4  font-medium rounded-lg text-sm px-4 py-2"
              >
                <AiOutlineSearch className="text-xl"></AiOutlineSearch>
              </button>
            </div>
          </form>

          <div className="h-3/5 w-full mt-8 overflow-auto gap-6 grid grid-cols-1 md:grid-cols-2 ">
            {quizs.map((quiz, index) => (
              <div
                key={index}
                className="flex flex-col justify-center bg-gradient-to-br from-slate-200 h-52 to-blue-200 items-start p-6 rounded-lg shadow-xl"
              >
                <div>
                  <h3 className="text-xl uppercase font-bold text-[#393939]">
                    {quiz.quizName}
                  </h3>
                  <div className="mt-4">
                    <div className="flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 text-blue-700"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <p className="text-[#393939] text-sm ml-2">
                        1 menit / kolom
                      </p>
                    </div>
                    <div className="flex flex-row items-center mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 text-blue-700"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                        />
                      </svg>

                      <p className="text-[#393939] text-sm ml-2">
                        {quiz.jumlahKoloms} kolom
                      </p>
                    </div>
                  </div>

                  <div className="mt-4 flex items-center gap-2">
                    <button
                      onClick={() => {
                        if (quiz.password == null) handleNextPage(quiz.quizId);
                        else handleModalPasswordToNextPage(quiz.quizId, index);
                      }}
                      className="text-sm tracking-wide rounded py-2 px-4 bg-[#FF914D] text-white hover:bg-[#e8742b]
                      transition duration-500 ease-in-out shadow-xl"
                    >
                      Mulai Tes
                    </button>
                    {quiz.password != null && (
                      <div className="bg-red-500 p-2 rounded text-white">
                        <AiFillLock />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-w-[400px] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Quiz Private</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form>
                    <div className="mb-6">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-semibold text-gray-700 "
                      >
                        Password Quiz
                      </label>
                      <input
                        type="text"
                        id="password"
                        name="password"
                        className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="masukan password quiz"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-slate-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={handleSubmitPassword}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}

export default List;
