import React, { useEffect, useState } from "react";
import Sidebar from "../../../Items/Sidebar";
import { AiFillEye } from "react-icons/ai";
import useVerify from "../../../useVerify";
import moment from "moment";
import { Link } from "react-router-dom";

export default function AktivasiUser() {
  useVerify("admin");

  const [hasils, setHasils] = useState([]);
  const [keySearch, setKeySearch] = useState("");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchAllHasil(keySearch, page);
    // eslint-disable-next-line
  }, []);

  async function fetchAllHasil(key, page) {
    let uri = "";
    if (key === "")
      uri = `${process.env.REACT_APP_BASE_URL}/api/hasils/page/${page}`;
    else
      uri = `${process.env.REACT_APP_BASE_URL}/api/hasils/search/${key}/page/${page}`;

    // Fetch data from API
    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        setTotalPages(data.totalPage);
        setHasils(data.data);
        setPage(page);
      });
  }

  const handleSearchChange = (event) => {
    setKeySearch(event.target.value);
    fetchAllHasil(event.target.value, 1);
  };

  const handlePreviousPage = () => {
    fetchAllHasil(keySearch, page - 1);
  };

  const handleNextPage = () => {
    fetchAllHasil(keySearch, page + 1);
  };

  return (
    <div className="w-full flex flex-row">
      <div className="w-1/5">
        <Sidebar />
      </div>
      <div className="w-4/5 bg-slate-100 p-20 ">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">
          Data Nilai Siswa
        </h1>
        <div className="mt-12">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="py-3 pl-2">
                <div className="relative max-w-xs">
                  <label htmlFor="hs-table-search" className="sr-only">
                    Search
                  </label>
                  <input
                    type="text"
                    name="hs-table-search"
                    id="hs-table-search"
                    className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-slate-500 focus:ring-slate-500"
                    placeholder="Search..."
                    value={keySearch}
                    onChange={handleSearchChange}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                    <svg
                      className="h-3.5 w-3.5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="p-1.5 w-full inline-block align-middle h-screen">
                <div className="h-3/5 overflow-auto border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="flex items-center px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">Name</span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">Tes</span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">
                            Total Skor
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          Waktu Pengerjaan
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {hasils.map((hasil, index) => (
                        <tr key={hasil.hasilId}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {(page - 1) * 10 + index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {hasil.namaLengkap}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {hasil.namaTest}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {hasil.totalCorrectHasil} / <span>500</span>
                          </td>
                          <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                            {moment(hasil.createdAt).format("DD MMMM YYYY")}
                          </td>
                          <td className=" text-xl flex justify-center items-center py-4 font-medium text-center whitespace-nowrap">
                            <Link to={`/detailnilaiadmin/${hasil.hasilId}`}>
                              <AiFillEye className="text-blue-500 hover:text-blue-800" />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-between py-4">
                  <button
                    onClick={page > 1 ? handlePreviousPage : null}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-l"
                  >
                    Previous
                  </button>
                  <div className="flex items-center">
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                      (num) => (
                        <button
                          key={num}
                          onClick={() => {
                            fetchAllHasil(keySearch, num);
                          }}
                          className={`${
                            page === num ? "bg-gray-400" : "bg-gray-300"
                          } hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-full mr-2`}
                        >
                          {num}
                        </button>
                      )
                    )}
                  </div>
                  <button
                    onClick={page < totalPages ? handleNextPage : null}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-medium py-2 px-4 rounded-r"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
