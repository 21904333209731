import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaKey } from "react-icons/fa";

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const accountCookie = Cookies.get("account");
  const navigate = useNavigate();

  const [role, setRole] = useState("");

  useEffect(() => {
    if (accountCookie !== undefined) {
      const accountTmp = JSON.parse(accountCookie);
      setRole(accountTmp.role);
    }
  }, [accountCookie]);

  function handleLogout() {
    Cookies.remove("account");
    navigate("/login");
  }

  return (
    <nav className="w-full backdrop-blur-sm bg-white/10 shadow">
      <div className="justify-between px-4 mx-auto lg:max-w-4xl md:items-center md:flex md:px-8">
        <div>
          <div className="flex items-center justify-between py-3 md:py-3 md:block md:justify-center">
            <Link to="/">
              <img
                className="mx-auto h-12 w-auto"
                src="../assets/img/logo-oc-1.png"
                alt="Owl Course"
              />
            </Link>
            <div className="md:hidden">
              <button
                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`flex-1 justify-self-center pb-8 mt-8 md:block md:pb-0 md:mt-0 ${
              navbar ? "block" : "hidden"
            }`}
          >
            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
              <li className="text-slate-800 hover:font-semibold border-b border-[#FF914D]/70 pb-2">
                <Link to="/list">Mulai Tes</Link>
              </li>

              <li className="text-slate-800 hover:font-semibold border-b border-[#FF914D]/70 pb-2">
                <Link to="/historylistsemua">Hasil</Link>
              </li>
              {accountCookie === undefined ? (
                <Link to="/login">
                  <li
                    className="flex flex-row text-white bg-slate-700 text-semibold
                transition duration-500 ease-in-out py-2 px-4 text-sm rounded shadow-lg
                justify-center items-center
                hover:bg-orange-800 mt-4 mb-2 md:my-0 hover:text-white"
                  >
                    Login
                  </li>
                </Link>
              ) : (
                <div onClick={handleLogout}>
                  <li
                    className="flex flex-row text-white bg-slate-700 text-semibold
                transition duration-500 ease-in-out py-2 px-4 text-sm rounded shadow-lg
                justify-center items-center
                hover:bg-orange-800 mb-4 md:mb-0 hover:text-white"
                  >
                    Logout
                  </li>
                </div>
              )}
              {role === "admin" ? (
                <Link to="/nilaisiswa">
                  <li
                    className="flex flex-row border border-orange-600 text-orange-600 text-semibold
                transition duration-500 ease-in-out py-2 px-4 text-sm rounded shadow-lg
                justify-center items-center
                hover:bg-orange-600 hover:text-white"
                  >
                    <FaKey className="mr-1" />
                    Admin
                  </li>
                </Link>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
