import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../Items/Navbar";
import useVerify from "../useVerify";
import Title from "./items/Title";

function HistoryListPerTes() {
  useVerify("user");

  const [hasil, setHasil] = useState([]);

  const accountCookie = Cookies.get("account");
  const navigate = useNavigate();

  useEffect(() => {
    if (accountCookie === undefined) {
      return navigate("/login");
    }

    // get account data
    const accountTmp = JSON.parse(accountCookie);
    // console.log(accountTmp.userId);

    // Fetch data from API
    fetch(`${process.env.REACT_APP_BASE_URL}/api/hasils/${accountTmp.userId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
        console.log(hasil);
      });

    // eslint-disable-next-line
  }, [accountCookie]);

  return (
    <div className="w-full h-screen bg-slate-200 o">
      <Navbar></Navbar>
      <div className="w-full flex flex-col items-center h-screen bg-slate-200 overflow-auto">
        <div className="w-full md:w-2/3 flex flex-col justify-center items-center">
          <Title />
          <hr />
          <div className="mt-12 w-full inline-block align-middle h-screen overflow-auto">
            <div className="overflow-hidden border grid gap-4 grid-cols-3">
              {hasil.map((item) => (
                <Link
                  key={item.hasilId}
                  to="/historylistpertesdetail"
                  state={{ item }}
                  className="uppercase py-6 bg-black/80 hover:bg-black rounded-lg flex flex-col text-white text-center cursor-pointer"
                >
                  <p className="text-sm">tes</p>
                  <h3 className="text-xl font-bold tracking-wide">
                    {item.namaTest}
                  </h3>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryListPerTes;
