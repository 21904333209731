export function Pilihan(props) {
  return (
    <label
      onClick={() => props.handleChange(props.value)}
      className="block px-3 md:px-5 text-lg py-2 md:py-3 select-none rounded cursor-pointer 
      font-semibold border-[1px] border-gray-800 hover:bg-slate-200"
    >
      {String.fromCharCode(props.value + 65)}
    </label>
  );
}
