import React, { useEffect, useState } from "react";
import Navbar from "./Items/Navbar";
import { Link } from "react-router-dom";
import useVerify from "./useVerify";
import Cookies from "js-cookie";

function Home() {
  useVerify("user");

  const [nama, setNama] = useState("");
  const accountCookie = Cookies.get("account");

  useEffect(() => {
    if (accountCookie !== undefined) {
      const accountTmp = JSON.parse(accountCookie);
      setNama(accountTmp.name);
    }
  }, [accountCookie]);

  return (
    <div className="w-full flex flex-col items-center h-screen bg-gradient-to-br from-orange-300 to-blue-200">
      <Navbar />
      <div
        className="md:my-20 w-full md:w-2/3 
      flex flex-col md:flex-row 
      justify-center 
      items-center"
      >
        <div className="mt-6 md:mt-0 w-full md:w-2/5 flex flex-col justify-start items-center md:hidden">
          <div
            className="mb-4 md:mb-0 flex flex-row gap-2 
          items-center py-2 px-4 bg-black rounded-full shadow-xl "
          >
            <h3 className=" text-xs font-bold text-slate-100">
              Halo, <span className="text-[#FF914D]">{nama}</span>
            </h3>
          </div>
          <img
            src="./assets/img/animasi.png"
            alt="owl-course"
            className="w-3/6 md:w-5/6 h-auto"
          />
        </div>
        <div
          className=" w-4/5 md:w-3/5 px-12 md:px-18 backdrop-blur-xl shadow-md bg-white/50 rounded-xl py-10
        mt-4 md:mt-0"
        >
          <h1 className="text-3xl md:text-5xl font-extrabold text-black/90">
            Tes Kecermatan
          </h1>
          <p className="w-full text-sm md:text-lg mt-2 md:pr-1 md:mt-4 leading-5">
            Sistem seleksi berbasis komputer yang dapat membantu untuk melihat
            hasil ujian peserta yang memenuhi standar minimal kompetisi.
          </p>
          <div className="mt-4 md:mt-6 flex flex-row gap-4">
            <Link
              to="/list"
              className="py-2 px-4
              text-md font-semibold text-slate-100 traking-wider text-center
              rounded bg-orange-600 hover:bg-orange-700 shadow-lg
              transition duration-300 ease-in-out"
            >
              Mulai Tes
            </Link>
          </div>
        </div>
        <div className="w-2/5 flex flex-col justify-center items-center">
          <img
            src="./assets/img/animasi.png"
            alt="owl-course"
            className="w-5/6 h-auto md:block hidden"
          />
          <div
            className="mb-4 md:mb-0 md:flex flex-row gap-2 
          items-center py-2 px-4 bg-black rounded-full shadow-xl w-fit hidden"
          >
            <h3 className=" text-xs font-bold text-slate-100 md:block hidden">
              Halo, <span className="text-[#FF914D]">{nama}</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
