import React, { useState } from "react";
import Sidebar from "../../Items/Sidebar";
import { useNavigate } from "react-router-dom";
import PanduanStatis from "./panduan/PanduanStatis";
import useVerify from "../../useVerify";

export default function QuizStatis() {
  useVerify("admin");

  // eslint-disable-next-line
  const [error, setError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [judul, setJudul] = useState("");
  const [k1, setK1] = useState("");
  const [k2, setK2] = useState("");
  const [k3, setK3] = useState("");
  const [k4, setK4] = useState("");
  const [k5, setK5] = useState("");
  const [k6, setK6] = useState("");
  const [k7, setK7] = useState("");
  const [k8, setK8] = useState("");
  const [k9, setK9] = useState("");
  const [k10, setK10] = useState("");

  const navigate = useNavigate();

  const handleJudulChange = (event) => {
    setJudul(event.target.value);
  };

  const handleK1Change = (event) => {
    setK1(event.target.value);
  };
  const handleK2Change = (event) => {
    setK2(event.target.value);
  };
  const handleK3Change = (event) => {
    setK3(event.target.value);
  };
  const handleK4Change = (event) => {
    setK4(event.target.value);
  };
  const handleK5Change = (event) => {
    setK5(event.target.value);
  };
  const handleK6Change = (event) => {
    setK6(event.target.value);
  };
  const handleK7Change = (event) => {
    setK7(event.target.value);
  };
  const handleK8Change = (event) => {
    setK8(event.target.value);
  };
  const handleK9Change = (event) => {
    setK9(event.target.value);
  };
  const handleK10Change = (event) => {
    setK10(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Kirim data register ke API
    fetch(`${process.env.REACT_APP_BASE_URL}/api/quiz/add`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        judul,
        k1,
        k2,
        k3,
        k4,
        k5,
        k6,
        k7,
        k8,
        k9,
        k10,
      }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        // if (data.status === "sukses") {
        //   Cookies.set("account", JSON.stringify(data.data), { expires: 7 });
        // } else {
        //     console.log(data.message); // tampilkan pesan error
        // }
        navigate("/buatquiz"); // arahkan ke halaman home
      });
  };

  return (
    <div className="w-full flex flex-row h-screen ">
      <div className="w-1/5">
        <Sidebar/>
      </div>
      <div className="w-4/5 bg-slate-100 p-20 overflow-auto">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">Buat Tes</h1>
        <PanduanStatis />
        <div className="mt-12">
          <form action="#" method="POST" onSubmit={handleSubmit}>
            <div className="mb-6">
              <hr />
              <h3 className="text-2xl font-semibold mt-4 mb-6 text-gray-900">
                Isi Judul
              </h3>
              <label
                htmlFor="judul"
                className="block mb-2 text-sm font-semibold text-gray-700 "
              >
                Judul Quiz
              </label>
              <input
                type="text"
                id="judul"
                name="judul"
                value={judul}
                onChange={handleJudulChange}
                autoComplete="judul"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="masukan judul quiz"
                required
              />
            </div>
            <hr />
            <h3 className="text-2xl font-semibold mt-4 mb-6 text-gray-900">
              Isi Kolom
            </h3>
            <div className="mb-6">
              <label
                htmlFor="k1"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 1
              </label>
              <input
                type="text"
                id="k1"
                name="k1"
                // value={input.value}
                // onChange={(event) => handleInputChange(event, index)}
                value={k1}
                onChange={handleK1Change}
                autoComplete="k1"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 1"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k2"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 2
              </label>
              <input
                type="text"
                id="k2"
                name="k2"
                value={k2}
                onChange={handleK2Change}
                autoComplete="k2"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 2"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k3"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 3
              </label>
              <input
                type="text"
                id="k3"
                name="k3"
                value={k3}
                onChange={handleK3Change}
                autoComplete="k3"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 3"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k4"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 4
              </label>
              <input
                type="text"
                id="k4"
                name="k4"
                value={k4}
                onChange={handleK4Change}
                autoComplete="k4"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 4"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="k5"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 5
              </label>
              <input
                type="text"
                id="k5"
                name="k5"
                value={k5}
                onChange={handleK5Change}
                autoComplete="k5"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 5"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k6"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 6
              </label>
              <input
                type="text"
                id="k6"
                name="k6"
                value={k6}
                onChange={handleK6Change}
                autoComplete="k6"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 6"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="k7"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 7
              </label>
              <input
                type="text"
                id="k7"
                name="k7"
                value={k7}
                onChange={handleK7Change}
                autoComplete="k7"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 7"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k8"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 8
              </label>
              <input
                type="text"
                id="k8"
                name="k8"
                value={k8}
                onChange={handleK8Change}
                autoComplete="k8"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 8"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k9"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 9
              </label>
              <input
                type="text"
                id="k9"
                name="k9"
                value={k9}
                onChange={handleK9Change}
                autoComplete="k9"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 9"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>
            <div className="mb-6">
              <label
                htmlFor="k10"
                className="block mb-2 text-sm font-medium text-gray-700 "
              >
                Kolom 10
              </label>
              <input
                type="text"
                id="k10"
                name="k10"
                value={k10}
                onChange={handleK10Change}
                autoComplete="k10"
                className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="isi kolom 10"
                required
              />
              {error ? (
                <label className="text-xs text-red-600 ml-1">
                  Maksimal 5 Karakter
                </label>
              ) : (
                ""
              )}
            </div>

            <div
              //   type="submit"
              onClick={() => setShowModal(true)}
              className="border border-slate-800 text-slate-800 hover:text-white bg-transparent hover:bg-slate-800 
              font-semibold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center transition ease-in-out duration-500"
            >
              Buat Soal
            </div>
            {showModal ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">
                          Konfirmasi Pembuatan Quiz
                        </h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModal(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <div className="my-2 text-slate-500 text-lg leading-relaxed">
                          <p className=" pb-3">
                            Judul :{" "}
                            <span className="font-semibold uppercase">
                              {judul}
                            </span>
                          </p>

                          <table className="table-auto text-left text-sm">
                            <thead className="border-b">
                              <tr>
                                <th className="pr-12">Kolom</th>
                                <th>Soal Kolom</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Kolom 1</td>
                                <td>{k1}</td>
                              </tr>
                              <tr>
                                <td>Kolom 2</td>
                                <td>{k2}</td>
                              </tr>
                              <tr>
                                <td>Kolom 3</td>
                                <td>{k3}</td>
                              </tr>
                              <tr>
                                <td>Kolom 4</td>
                                <td>{k4}</td>
                              </tr>
                              <tr>
                                <td>Kolom 5</td>
                                <td>{k5}</td>
                              </tr>
                              <tr>
                                <td>Kolom 6</td>
                                <td>{k6}</td>
                              </tr>
                              <tr>
                                <td>Kolom 7</td>
                                <td>{k7}</td>
                              </tr>
                              <tr>
                                <td>Kolom 8</td>
                                <td>{k8}</td>
                              </tr>
                              <tr>
                                <td>Kolom 9</td>
                                <td>{k9}</td>
                              </tr>
                              <tr>
                                <td>Kolom 10</td>
                                <td>{k10}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </button>
                        <button
                          className="bg-slate-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Buat Soal
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}
