import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const [email, setEmail] = useState("");
  const [noHandphone, setNoHandphone] = useState("");
  const [namaLengkap, setNamaLengkap] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleNoHandphoneChange = (event) => {
    setNoHandphone(event.target.value);
  };

  const handleNamaLengkapChange = (event) => {
    setNamaLengkap(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      // Tampilkan pesan gagal jika password dan confirm password tidak sama
      toast.warn("Gagal, password dan confirm password tidak sama", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log("Gagal, password dan confirm password tidak sama");
      return;
    }

    // Kirim data register ke API
    fetch(`${process.env.REACT_APP_BASE_URL}/api/add_user`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        email,
        noHandphone,
        namaLengkap,
        password,
      }).toString(),
    })
      .then((response) => response.json()) // parse body menjadi object JavaScript
      .then((data) => {
        toast.success("Register berhasil, login dengan akun mu", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/login");
      });
  };

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-gradient-to-br from-orange-300 to-blue-200">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <img
        className="mx-auto h-24 w-auto"
        src="./assets/img/logo-oc-1.png"
        alt="Owl Course"
      />
      <div className="w-full flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8 ">
        <div className="w-full max-w-md space-y-8 backdrop-blur-xl rounded-xl bg-white/70 p-10">
          <div>
            <h2 className="mt-1 text-center text-3xl font-bold tracking-tight text-black/90">
              Register
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="remember" value="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  No Telp/HP
                </label>
                <input
                  id="nohp"
                  name="nohp"
                  type="text"
                  value={noHandphone} // ganti sama no tlp
                  onChange={handleNoHandphoneChange} // ganti sama no tlp
                  required
                  className="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="No telp/hp"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  No Telp/HP
                </label>
                <input
                  id="namaLengkap"
                  name="namaLengkap"
                  type="text"
                  value={namaLengkap} // ganti sama no tlp
                  onChange={handleNamaLengkapChange} // ganti sama no tlp
                  required
                  className="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Nama Lengkap"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Confirm Password"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md 
                bg-orange-600 hover:bg-orange-700 py-2 px-4 text-sm font-medium text-white "
              >
                Daftar
              </button>
            </div>
          </form>
          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/login"
                className="font-medium text-slate-900 hover:text-[#356DB8] transition ease-in-out duration-500"
              >
                Sudah punya akun?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
