import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useVerify from "./useVerify";

function CountdownPage() {
  useVerify("user");

  const location = useLocation();
  const { quizId } = location.state;

  // State untuk menyimpan waktu hitung mundur
  const [time, setTime] = useState(3);
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/koloms/${quizId}`)
      .then((response) => response.json())
      .then((data) => {
        setQuestions(data);
        // console.log(data);
      });
  }, [quizId]);

  // Setiap waktu diupdate, cek apakah waktu habis
  useEffect(() => {
    if (time === 0) {
      // Jika waktu habis, navigate ke page lain
      return navigate("/quiz", {
        state: { baseQuestion: questions, quizId: quizId },
      });
    }
    // eslint-disable-next-line
  }, [time]);

  // Setiap detik, kurangi waktu sebesar 1
  useEffect(() => {
    const id = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-slate-200">
      <h3 className="text-3xl md:text-5xl font-bold text-slate-800 text-center">
        Tes akan dimulai dalam
      </h3>
      <p className="text-3xl font-bold mt-2 text-slate-800 text-center">
        {time}
      </p>
    </div>
  );
}

export default CountdownPage;
