import React, { useEffect, useState } from "react";
import Sidebar from "../../Items/Sidebar";
import { FiUserCheck } from "react-icons/fi";
import { AiTwotoneDelete } from "react-icons/ai";
import useVerify from "../../useVerify";

export default function AktivasiUser() {
  useVerify("admin");

  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [query, setQuery] = useState("");

  useEffect(() => {
    // Fetch data user active from API
    fetchUsersNonActive(query);
    // eslint-disable-next-line
  }, []);

  async function fetchUsersNonActive(key) {
    let uri = "";
    if (key === "")
      uri = `${process.env.REACT_APP_BASE_URL}/api/users/non_active`;
    else uri = `${process.env.REACT_APP_BASE_URL}/api/users/non_active/${key}`;

    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      });
  }

  async function handleAccUser($userId) {
    // Fetch data user active from API
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/users/active/${$userId}`,
      {
        method: "PUT",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });

    await fetchUsersNonActive(query);
  }

  async function handleDeleteUser($userId) {
    // Fetch data user active from API
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${$userId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });

    await fetchUsersNonActive(query);
  }

  const handleSearch = (event) => {
    setQuery(event.target.value);
    fetchUsersNonActive(event.target.value);
  };

  return (
    <div className="w-full flex flex-row">
      <div className="w-1/5">
        <Sidebar/>
      </div>
      <div className="w-4/5 bg-slate-100 h-screen overflow-auto p-20">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">
          Aktivasi User
        </h1>
        <div className="mt-12">
          <div className="flex flex-col">
            <div className="overflow-x-auto">
              <div className="py-3 pl-2">
                <div className="relative max-w-xs">
                  <label htmlFor="hs-table-search" className="sr-only">
                    Search
                  </label>
                  <input
                    type="text"
                    name="hs-table-search"
                    id="hs-table-search"
                    className="block w-full p-3 pl-10 text-sm border-gray-200 rounded-md focus:border-slate-500 focus:ring-slate-500"
                    placeholder="Search..."
                    value={query}
                    onChange={handleSearch}
                  />
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                    <svg
                      className="h-3.5 w-3.5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="p-1.5 w-full inline-block align-middle">
                <div className="overflow-hidden border rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="flex items-center px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">Name</span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">
                            No Telp
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                        >
                          <span className="inline-flex items-center">
                            Email
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          Action
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {users.map((user, index) => (
                        <tr key={user.userId}>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {user.namaLengkap}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {user.noHandphone}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                            {user.email}
                          </td>

                          <td className=" text-xl flex justify-center items-center py-4 font-medium text-center whitespace-nowrap">
                            <button
                              onClick={() => {
                                handleDeleteUser(user.userId);
                              }}
                            >
                              <AiTwotoneDelete className="text-red-500 hover:text-red-800" />
                            </button>
                          </td>
                          {user.status === "active" ? (
                            <td className="px-6 py-4 text-center whitespace-nowrap text-green-500">
                              Terverifikasi
                            </td>
                          ) : (
                            <td className="px-6 py-4 text-center whitespace-nowrap text-white">
                              <button
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                className="py-1 px-3 font-regular text-xs bg-green-600 rounded shadow-xl"
                              >
                                Aktifkan User
                              </button>
                            </td>
                          )}

                          {showModal ? (
                            <>
                              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                  {/*content*/}
                                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                      <div className="flex flex-row justify-center items-center">
                                        <FiUserCheck className="text-2xl mr-3" />
                                        <h3 className=" text-3xl font-semibold">
                                          Aktivasi User
                                        </h3>
                                      </div>
                                      <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                      >
                                        ×
                                      </button>
                                    </div>
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                      <div className="my-2 text-slate-800 text-lg leading-relaxed">
                                        <p className=" pb-3 text-md">
                                          Aktifkan{" "}
                                          <span className="font-bold">
                                            {user.namaLengkap}
                                          </span>{" "}
                                          sebagai user Tes Kecermatan?
                                        </p>
                                      </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                      <button
                                        className="border-2 
                                        border-green-600 text-green-600 
                                        hover:text-slate-100 hover:bg-green-600 hover:border-transparent
                                        transition duration-500 ease-in-out 
                                        font-bold uppercase text-sm px-4 py-2 rounded shadow  mr-1 mb-1"
                                        onClick={() => {
                                          setShowModal(false);
                                          handleAccUser(user.userId);
                                        }}
                                      >
                                        Ya, Aktifkan User
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
