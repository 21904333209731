import React from "react";
import { Link } from "react-router-dom";
import { MdQuiz } from "react-icons/md";
import { RiNumbersFill } from "react-icons/ri";
import { FaBookmark, FaUserAlt, FaUserPlus, FaUserCheck } from "react-icons/fa";
import { AiOutlineFolderAdd, AiOutlineUnorderedList } from "react-icons/ai";

function Sidebar() {
  return (
    <div className="w-max md:w-1/5 bg-[#FF914D] h-screen fixed">
      <div className="h-1/6 bg-[#FF914D] flex justify-center items-center">
        <Link to="/">
          <img
            className="mx-auto h-20 w-auto"
            src="../assets/img/logo-white.png"
            alt="Owl Course"
          />
        </Link>
      </div>
      <hr />
      <div className="h-5/6 mx-8 my-10">
        <div className="my-8">
          <h4 className="bg-white p-4 rounded flex flex-row items-center gap-3 text-xl text-[#FF914D] mb-4">
            <FaUserAlt className="text-3xl" />
            User
          </h4>
          <Link to="/useraktif">
            <button className="ml-6 text-white flex flex-row items-center gap-2 mb-4 hover:font-bold transition">
              <FaUserPlus className="text-2xl" />
              user aktif
            </button>
          </Link>
          <Link to="/aktivasiuser">
            <button className="ml-6 text-white flex flex-row items-center gap-2 hover:font-bold transition">
              <FaUserCheck className="text-2xl" />
              aktivasi user
            </button>
          </Link>
        </div>
        <div className="my-8">
          <h4 className="bg-white p-4 rounded flex flex-row items-center gap-3 text-xl text-[#FF914D] mb-4">
            <MdQuiz className="text-3xl" />
            Tes
          </h4>
          <Link to="/buatquiz">
            <button className="ml-6 text-white flex flex-row items-center gap-2 mb-4 hover:font-bold transition">
              <AiOutlineFolderAdd className="text-2xl" />
              Buat Tes
            </button>
          </Link>
          <Link to="/listquiz">
            <button className="ml-6 text-white flex flex-row items-center gap-2 hover:font-bold transition">
              <AiOutlineUnorderedList className="text-2xl" />
              Daftar Tes
            </button>
          </Link>
        </div>
        <div className="mt-8">
          <h4 className="bg-white p-4 rounded flex flex-row items-center gap-3 text-xl text-[#FF914D] mb-4">
            <FaBookmark className="text-3xl" />
            Hasil
          </h4>
          <Link
            to="/nilaisiswa"
            className="ml-6 text-white flex flex-row items-center gap-2 mb-4"
          >
            <RiNumbersFill className="text-xl" />
            Nilai Siswa
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
