import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function useVerify(role) {
  const accountCookie = Cookies.get("account");
  const navigate = useNavigate();

  useEffect(() => {
    if (accountCookie === undefined) return navigate("/login");

    // convert account cookie to json
    const accountTmp = JSON.parse(accountCookie);

    if (role === "user") verifyUser(accountTmp);
    else verifyAdmin(accountTmp);

    // eslint-disable-next-line
  }, [accountCookie]);

  async function verifyUser(accountTmp) {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        email: accountTmp.email,
        password: accountTmp.password,
      }).toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "gagal") return navigate("/login");
      });
  }

  async function verifyAdmin(accountTmp) {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/verify_admin`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        email: accountTmp.email,
        password: accountTmp.password,
      }).toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "gagal") return navigate("/");
      });
  }
}

export default useVerify;
