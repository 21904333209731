import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../Items/Navbar";
import useVerify from "../useVerify";
import Title from "./items/Title";

function HistoryList() {
  useVerify("user");

  const [hasil, setHasil] = useState([]);

  const accountCookie = Cookies.get("account");
  const navigate = useNavigate();

  useEffect(() => {
    if (accountCookie === undefined) {
      return navigate("/login");
    }

    // get account data
    const accountTmp = JSON.parse(accountCookie);
    // console.log(accountTmp.userId);

    // Fetch data from API
    fetch(`${process.env.REACT_APP_BASE_URL}/api/hasils/${accountTmp.userId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
        console.log(hasil);
      });

    // eslint-disable-next-line
  }, [accountCookie]);

  function getTotalResult(data) {
    return data.reduce((acc, curr) => acc + Number(curr), 0);
  }

  return (
    <div className="w-full h-screen bg-slate-300 ">
      <Navbar></Navbar>
      <div className="w-full flex flex-col items-center">
        <div className="w-full md:w-2/3 h-full flex flex-col justify-center items-center">
          <Title />
          <hr />
          <div className="w-full mt-12 ">
            {hasil.map((item) => (
              <div
                key={item.hasilId}
                className="border bg-white rounde-xl flex flex-col justify-center items-start p-4 rounded shadow-xl mb-4"
              >
                <div>
                  <h3 className="text-xl md:text-3xl font-bold text-[#393939]">
                    {item.namaTest}
                  </h3>
                  <div className="mt-4">
                    Total skor{" "}
                    <span className="font-bold">
                      {getTotalResult(item.results)}
                    </span>
                  </div>
                  <div>
                    <Link to="/history" state={{ item }}>
                      <button
                        className="text-xs tracking-wide rounded py-1 px-3 mt-4 bg-[#FF914D] font-semibold text-white hover:bg-[#e8742b]
                            transition duration-500 ease-in-out shadow-xl "
                      >
                        Detail Hasil
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryList;
