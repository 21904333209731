import React from "react";
import Sidebar from "../../Items/Sidebar";
import { Link } from "react-router-dom";
import useVerify from "../../useVerify";

export default function BuatQuiz() {
  useVerify("admin");

  return (
    <div className="w-full flex flex-row">
      <div className="w-1/5">
        <Sidebar/>
      </div>
      <div className="w-4/5 bg-slate-100 p-20 h-screen">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">Buat Tes</h1>
        <div className="mt-12">
          <div className="grid grid-cols-3 gap-4">

            <Link
              to="/createdinamisquiz"
              className="flex flex-col justify-center items-center h-24 rounded-md shadow-xl bg-slate-600
                hover:bg-slate-800 group transition duration-500 ease-in-out"
            >
              <h3 className="text-2xl group-hover:text-3xl group-hover:text-[#FF914D] text-white font-bold tracking-wide transition duration-500 ease-in-out">
                Tes
              </h3>
              <h4 className="text-xl text-white">Kolom Dinamis</h4>
            </Link>

            <Link
              to="/createautoquiz"
              className="flex flex-col justify-center items-center h-24 rounded-md shadow-xl bg-slate-600
                hover:bg-slate-800 group transition duration-500 ease-in-out"
            >
              <h3 className="text-2xl group-hover:text-3xl group-hover:text-[#FF914D] text-white font-bold tracking-wide transition duration-500 ease-in-out">
                Tes
              </h3>
              <h4 className="text-xl text-white">Kolom Auto</h4>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
