import React from "react";

function PanduanDinamis() {
    return (
        <div>
          <div className="mt-6">
            <p className="text-sm text-slate-800">
              Membuat quiz tanpa batasan kolom. setiap kolom yang dibuat akan
              otomatis memiliki soalnya sendiri dan juga memiliki waktu pengerjaan
              1 menit. ada 2 bagian yang perlu diisi diantaranya bagian judul dan
              bagian kolom :
            </p>
            <li className="text-sm text-slate-800">
              Judul : isi judul quiz (contoh : Angka Hilang)
            </li>
            <li className="text-sm text-slate-800">
              Kolom : isi pertanyaan kolom (contoh : 1, 3, 4, 6, 7)
              <span className="text-red-600 text-xs font-semibold">
                {" "}
                *harus 5 karakter
              </span>
            </li>
          </div>
          <div className="border rounded mt-4 p-6 text-xs text-slate-800">
            <p className="mb-1">
              * jika kolom diisi "1, 3, 4, 6, 7" maka secara otomatis soal akan
              terbentuk dengan bentuk :
            </p>
            <p>-----------------</p>
            <p>| 1 | 3 | 4 | 6 | 7 |</p>
            <p>-----------------</p>
            <p>| A | B | C | D | E |</p>
            <p>-----------------</p>
            <p></p>
          </div>
        </div>
    );
}

export default PanduanDinamis