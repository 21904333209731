import {useNavigate} from "react-router-dom"
import { MdArrowBackIos } from "react-icons/md";
function Title() {
  const navigate = useNavigate();

  return (
    <div className="mt-12 w-full flex flex-row justify-start items-center">
      <button onClick={() => navigate(-1)} className="text-black/90">
              <MdArrowBackIos className="text-4xl"></MdArrowBackIos>
            </button>
      <h1 className="font-extrabold text-4xl mb-2 text-black/90">Riwayat Mu</h1>
      {/* <div className="flex flex-row gap-2 text-xs text-orange-600 ">
                <Link to="/historylistsemua">
                    <button className="py-2 px-4 border border-orange-600 rounded  
                    hover:bg-orange-600 hover:text-white
                    transition duration-500 ease-in-out">Semua</button>
                </Link>
                <Link to="/historylistpertes">
                    <button className="py-2 px-4 border border-orange-600 rounded  
                    hover:bg-orange-600 hover:text-white
                    transition duration-500 ease-in-out">Per Tes</button>
                </Link>
            </div> */}
    </div>
  );
}

export default Title;
