import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Navbar from "../Items/Navbar";
import Title from "./items/Title";
import { AiFillEye } from "react-icons/ai";
import Moment from "moment";
import useVerify from "../useVerify";

function HistoryListTesDetail() {
  useVerify("user");

  const [hasil, setHasil] = useState([]);
  const accountCookie = Cookies.get("account");

  useEffect(() => {
    // get account data
    const accountTmp = JSON.parse(accountCookie);
    // console.log(accountTmp.userId);

    // Fetch data from API
    fetch(`${process.env.REACT_APP_BASE_URL}/api/hasils/${accountTmp.userId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
        console.log(hasil);
      });

    // eslint-disable-next-line
  }, [accountCookie]);

  function getTotalResult(data) {
    return data.reduce((acc, curr) => acc + Number(curr), 0);
  }

  return (
    <div className="w-full h-screen bg-slate-200">
      <Navbar></Navbar>
      <div className="w-full flex flex-col items-center h-screen bg-slate-200 overflow-auto">
        <div className="w-full md:w-2/3 flex flex-col justify-center items-center">
          <Title />
          <hr />
          <div className="mt-12 w-full inline-block align-middle h-screen overflow-auto">
            <div className="overflow-hidden border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="flex items-center px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      <span className="inline-flex items-center">Tes</span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      <span className="inline-flex items-center">
                        Total Skor
                      </span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                    >
                      Waktu Pengerjaan
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                {hasil.map((item) => (
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {item.hasilId}
                      </td>
                      <td className="px-6 py-4 text-sm text-blue-600 uppercase whitespace-nowrap">
                        {item.namaTest}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {getTotalResult(item.results)} / <span>500</span>
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {Moment(item.createdAt).format("DD MMMM YYYY")}
                      </td>
                      <td className=" text-xl flex justify-center items-center py-4 font-medium text-center whitespace-nowrap">
                        <button>
                          <AiFillEye className="text-blue-500 hover:text-blue-800" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryListTesDetail;
