import React from "react";
import { Link, useLocation } from "react-router-dom";
import useVerify from "./useVerify";

function ResultPage() {
  useVerify("user");

  const location = useLocation();
  const { listCorrect } = location.state;
  const total = listCorrect.reduce((acc, current) => acc + current, 0);

  return (
    <div className="w-full h-screen mx-auto p-4 bg-[#FF914D] flex flex-col items-center justify-center">
      <div>
        <img
          className="mx-auto h-32 w-auto"
          src="./assets/img/logo-white.png"
          alt="OWL Course"
        />
      </div>
      <h3 className="text-2xl font-semibold text-white mt-8">Hasil</h3>
      <h2 className="text-4xl font-bold text-white text-center text mb-1">
        Nilai Ujian Mu Adalah
      </h2>
      <h1 className="text-6xl font-extrabold mb-12 text-white">{total}</h1>
      <Link
        to="/"
        className="py-2 px-4 text-sm shadow-sm font-semibold tracking-wide rounded text-[#FF914D]
        bg-gray-900"
      >
        Kembali
      </Link>
    </div>
  );
}

export default ResultPage;
