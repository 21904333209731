import React, { useEffect, useState } from "react";
import Sidebar from "../../../Items/Sidebar";
import { Link } from "react-router-dom";
import useVerify from "../../../useVerify";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLock,
  AiFillUnlock,
} from "react-icons/ai";

export default function Index() {
  useVerify("admin");

  const [quizs, setQuizs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [quizId, setQuizId] = useState("");

  useEffect(() => {
    fetchAllQuizs();
  }, []);

  function fetchAllQuizs() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/quizs`)
      .then((response) => response.json())
      .then((data) => {
        setQuizs(data);
      });
  }

  const handleNonactiveQuiz = async (quizId) => {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/quiz/nonactive/${quizId}`,
      {
        method: "PUT",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.location.reload(false);
      });
  };

  const handleActiveQuiz = async (quizId) => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/quiz/active/${quizId}`, {
      method: "PUT",
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.reload(false);
      });
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function handleModalPassword(index, quizId) {
    setShowModal(true);

    if (quizs[index].password != null) setPassword(quizs[index].password);
    else setPassword("");

    setQuizId(quizId);
  }

  async function handleSubmitPassword() {
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/quiz/password/${quizId}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ password }).toString(),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });

    setShowModal(false);
    fetchAllQuizs();
  }

  // let activeNonActiveBtn = quizs.map.status === 'active' ? '' : 'grayscale'

  return (
    <div className="w-full flex flex-row">
      <div className="w-1/5">
        <Sidebar />
      </div>
      <div className="w-max md:w-4/5 bg-slate-100 p-20 h-screen overflow-auto">
        <h1 className="text-5xl font-extrabold text-[#FF914D]">Daftar Tes</h1>
        <div className="mt-12">
          <div className="grid grid-cols-3 gap-x-8 gap-y-4">
            {quizs.map((quiz, index) => (
              <div key={index} className="w-full flex gap-2 h-24">
                <Link
                  to={`/detailquiz/${quiz.quizId}`}
                  className={`bg-slate-600 
                  rounded hover:bg-slate-800 transition duration-500 
                  ease-in-out group flex flex-col flex-grow justify-center pl-4 items-start
                  `}
                >
                  <h3 className="text-2xl group-hover:text-orange-500  text-white font-bold tracking-wide transition duration-500 ease-in-out">
                    {quiz.quizName}
                  </h3>
                  <h4 className="text-sm text-slate-100 group-hover:text-orange-500 transition duration-500 ease-in-out">
                    {quiz.jumlahKoloms} Kolom
                  </h4>
                </Link>
                <div>
                  {quiz.status === "active" ? (
                    <button
                      onClick={() => handleNonactiveQuiz(quiz.quizId)}
                      className="block p-1 bg-green-600 hover:bg-green-800
                  transition duration-500 ease-in-out text-white text-xl rounded mb-1"
                    >
                      <AiFillEye />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleActiveQuiz(quiz.quizId)}
                      className="block p-1 bg-red-600 hover:bg-red-800
                  transition duration-500 ease-in-out text-white text-xl rounded mb-1"
                    >
                      <AiFillEyeInvisible />
                    </button>
                  )}

                  {quiz.password === null ? (
                    <button
                      onClick={() => handleModalPassword(index, quiz.quizId)}
                      className="block p-1 bg-green-600 hover:bg-green-800
                  transition duration-500 ease-in-out text-white text-xl rounded pl"
                    >
                      <AiFillUnlock />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleModalPassword(index, quiz.quizId)}
                      className="block p-1 bg-red-600 hover:bg-red-800
                  transition duration-500 ease-in-out text-white text-xl rounded pl"
                    >
                      <AiFillLock />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-w-[400px] bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex gap-4 items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">Setup Password</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6">
                  <small className="text-red-500">
                    *note: untuk menonaktifkan password, kosongkan input dan
                    submit
                  </small>
                  <form>
                    <div className="mb-6">
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-semibold text-gray-700 "
                      >
                        Password Quiz
                      </label>
                      <input
                        type="text"
                        id="password"
                        name="password"
                        className="bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="masukan password quiz"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                    </div>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-slate-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={handleSubmitPassword}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
