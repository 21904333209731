import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BiTimeFive } from "react-icons/bi";
import Navbar from "../Items/Navbar";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJs,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
} from "chart.js";
import useVerify from "../useVerify";
import moment from "moment";

ChartJs.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

function HistoryPage() {
  useVerify("user");

  const [data, setData] = useState({
    labels: ["Kolom 1", "Kolom 2", "Kolom 3", "Kolom 4", "Kolom 5", "Kolom 6"],
    datasets: [
      {
        label: "Terjawab",
        data: [31, 14, 26, 23, 25, 24],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "#FF914D",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Benar",
        data: [25, 12, 23, 18, 19, 20],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "green",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
      {
        label: "Salah",
        data: [6, 2, 3, 5, 6, 4],
        fill: true,
        backgroundColor: "transparent",
        borderColor: "red",
        pointBorderColor: "black",
        pointBorderWidth: 1,
      },
    ],
  });

  const options = {
    plugins: {
      legend: true,
    },
    scales: {
      x: {
        grid: {
          display: true,
        },
      },
      y: {
        min: 0,
        max: 50,
      },
    },
  };

  const { hasilId } = useParams();

  const [hasil, setHasil] = useState({ kolomAnswer: [] });
  const navigate = useNavigate();

  useEffect(() => {
    fetchDetailHasilByHasilId(hasilId);
  }, [hasilId]);

  useEffect(() => {
    if (hasil) {
      // Buat array labels sesuai dengan panjang array kolomAnswer
      const labels = hasil.kolomAnswer.map(
        (answer, index) => `Kolom ${index + 1}`
      );

      const dataTerjawab = hasil.kolomAnswer.map(
        (answer) => answer.totalJawaban
      );

      const dataBenar = hasil.kolomAnswer.map((answer) => answer.benar);

      const dataSalah = hasil.kolomAnswer.map((answer) => answer.salah);

      setData((prevData) => ({
        labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataTerjawab,
          },
          {
            ...prevData.datasets[1],
            data: dataBenar,
          },
          {
            ...prevData.datasets[2],
            data: dataSalah,
          },
        ],
      }));
    }
  }, [hasil]);

  async function fetchDetailHasilByHasilId(hasilId) {
    await fetch(`${process.env.REACT_APP_BASE_URL}/api/hasil/${hasilId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasil(data);
      });
  }

  return (
    <div className="h-screen w-full bg-slate-200">
      <Navbar />
      <div className="w-full flex flex-col justify-center items-center bg-slate-200">
        <div className="my-12 w-3/4">
          <h1 className="font-extrabold text-4xl  text-left text-[#FF914D]">
            Detail Hasil
          </h1>
          <button onClick={() => navigate(-1)} className="text-red-600">
              back
            </button>
          
        </div>
        <div className="w-3/4 flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 border p-0 md:p-4">
            <h3 className="text-2xl font-bold text-slate-900">
              {hasil.namaTest}
            </h3>
            <div className="my-4 flex flex-row items-center">
              <BiTimeFive className="mr-1"></BiTimeFive>
              <p className="text-xs md:text-sm">
                {moment(hasil.createdAt).format("DD MMMM YYYY")}
              </p>
            </div>
            <div className="w-full md:w-5/6 h-fit bg-white p-2 rounded-xl shadow-xl">
              <Line data={data} options={options}></Line>
            </div>
          </div>
          <div className="md:w-1/2 mt-12 w-full inline-block align-middle h-screen overflow-auto">
            <div className="">
              <table className="min-w-full divide-y divide-slate-600">
                <thead className="bg-white text-slate-800 uppercase">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-2 text-xs font-bold text-left"
                    >
                      Kolom
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-2 text-xs font-bold text-center"
                    >
                      Tabel
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-2 text-xs font-bold text-center"
                    >
                      Total Terjawab
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-2 text-xs font-bold text-center"
                    >
                      Benar
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-2 text-xs font-bold text-center"
                    >
                      Salah
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {hasil.kolomAnswer.map((kolom, index) => (
                    <tr key={kolom.kolomAnswerId}>
                      <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 uppercase whitespace-nowrap">
                        {kolom.kolom}
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {kolom.totalJawaban} / 50
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {kolom.benar}
                      </td>
                      <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {kolom.salah}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryPage;
