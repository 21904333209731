import React, { useEffect, useState } from "react";
import { Pilihan } from "./pilihan";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useVerify from "./useVerify";

function QuizPage() {
  useVerify("user");

  const location = useLocation();
  const { baseQuestion, quizId } = location.state;
  const [missingOptions, setMissingOptions] = useState([]);

  const [rightAnswer, setRightAnswer] = useState(0);

  const [time, setTime] = useState(60);
  const [intervalId, setIntervalId] = useState(null);

  const [sesi, setSesi] = useState(0);

  const [totalCorrect, setTotalCorrect] = useState(0);
  const [listCorrect, setListCorrect] = useState([]);

  const navigate = useNavigate();

  const [account, setAccount] = useState({});

  const [saveQuestions, setSaveQuestions] = useState([[]]);
  const [answerUser, setAnswerUser] = useState([[]]);
  const [correctUser, setCorrectUser] = useState([[]]);

  const [totalCorrectHasil, setTotalCorrectHasil] = useState(0);

  const [totalQuestionGenerated, setTotalQuestionGenerated] = useState(0);

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useEffect(() => {
    // get account data
    setAccount(JSON.parse(Cookies.get("account")));
  }, []);

  useEffect(() => {
    // generate first question
    generateQuestion();

    const id = setInterval(() => {
      setTime((time) => time - 1);
    }, 1000);
    setIntervalId(id);

    return () => clearInterval(id);
    // eslint-disable-next-line
  }, [sesi, baseQuestion]);

  // menghentikan waktu mundur jika sudah 00:00
  useEffect(() => {
    if (time === 0) {
      sesiFinished();
    }
    // eslint-disable-next-line
  }, [time]);

  async function sesiFinished() {
    // insert totalCorrect to listTotalCorrect
    const newList = listCorrect.concat(totalCorrect);
    setListCorrect(newList);

    if (sesi < baseQuestion.length - 1) {
      setTotalCorrect(0);
      setTime(60);
      setSesi((sesi) => sesi + 1);
      setTotalQuestionGenerated(0);
    } else {
      setShowLoadingModal(true);
      clearInterval(intervalId);

      // Kirim data hasil quiz ke server
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/add_hasil`,
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            userId: account.userId,
            quizId: quizId,
            totalCorrectHasil: totalCorrectHasil,
          }).toString(),
        }
      );
      response = await response.json();
      await addDataAnswerKolomsAndJawabans(response.hasilId, newList);

      setShowLoadingModal(false);
      navigate("/result", {
        state: { listCorrect: newList },
      });
    }
  }

  function generateQuestion() {
    // ambil 1 index acak
    const randomTmp = Math.floor(Math.random() * 5);
    setRightAnswer(randomTmp);

    // acak missing questions
    let missingTmp = [...baseQuestion[sesi].kolom];
    missingTmp.splice(randomTmp, 1);
    missingTmp = _.shuffle(missingTmp);
    setMissingOptions(missingTmp);
  }

  const handleChange = (value) => {
    const userAnswer = value;

    if (userAnswer === rightAnswer) {
      setTotalCorrect((totalCorrect) => totalCorrect + 1);
      setTotalCorrectHasil((totalCorrectHasil) => totalCorrectHasil + 1);
      setCorrectUser(handleSaveState(correctUser, 1));
    } else {
      setCorrectUser(handleSaveState(correctUser, 0));
    }
    setAnswerUser(
      handleSaveState(answerUser, baseQuestion[sesi].kolom[userAnswer])
    );

    setSaveQuestions(handleSaveState(saveQuestions, missingOptions));

    generateQuestion();

    // pembatasan 50 soal
    if (totalQuestionGenerated + 1 >= 50) {
      sesiFinished();
    } else {
      setTotalQuestionGenerated((value) => value + 1);
    }
  };

  function handleSaveState(array, newItem) {
    const newSaveState = [...array];
    if (!newSaveState[sesi]) newSaveState[sesi] = [];
    newSaveState[sesi].push(newItem);
    return newSaveState;
  }

  async function addDataAnswerKolomsAndJawabans(hasilId, kolomAnswer) {
    return Promise.all(
      kolomAnswer.map(async (input, index) => {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/add_kolom-answer`,
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              hasilId: hasilId,
              kolomId: baseQuestion[index].kolomId,
              totalCorrectKolom: input,
            }).toString(),
          }
        );
        response = await response.json();
        await addDataJawabans(response.kolomAnswerId, index);
        return response;
      })
    );
  }

  async function addDataJawabans(kolomAnswerId, index) {
    if (!saveQuestions[index]) saveQuestions[index] = [];

    return Promise.all(
      saveQuestions[index].map(async (input, idx) => {
        let response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/add_jawaban`,
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              kolomAnswerId: kolomAnswerId,
              question: input,
              answer: answerUser[index][idx],
              correct: correctUser[index][idx],
            }).toString(),
          }
        );
        response = await response.json();
        return response;
      })
    );
  }

  return (
    <>
      {/* <div className="container mx-auto py-4 max-w-2xl"> */}
      <div className="container mx-auto py-4 w-full ">
        <div className="">
          <form className="p-4 flex flex-col items-center">
            <img
              className="mt-8 mb-12 mx-auto h-20 w-auto"
              src="./assets/img/logo-oc-1.png"
              alt="OWL Course"
            />
            <h1 className="text-4xl items-start w-full max-w-lg font-bold ml-4 mb-3 text-[#FF914D]">
              Quiz Kecermatan
            </h1>
            <p className="ml-4 w-full max-w-lg">Total benar : {totalCorrect}</p>
            <label className="block font-bold mb-2 w-full max-w-lg ml-4">
              {time > 0
                ? `${Math.floor(time / 60)}:${
                    time % 60 < 10 ? `0${time % 60}` : time % 60
                  }`
                : "Waktu habis"}
            </label>
            <table className="w-full max-w-lg border-[#3F3B3C] border-[1px] rounded-lg mb-4">
              <tbody>
                <tr>
                  {baseQuestion[sesi].kolom.map((option) => (
                    <td
                      key={option}
                      className="border-[#3F3B3C] border-[1px] p-2 text-center text-3xl font-bold"
                    >
                      {option}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="border-[#3F3B3C] border-[1px] p-2 text-center">
                    A
                  </td>
                  <td className="border-[#3F3B3C] border-[1px] p-2 text-center">
                    B
                  </td>
                  <td className="border-[#3F3B3C] border-[1px] p-2 text-center">
                    C
                  </td>
                  <td className="border-[#3F3B3C] border-[1px] p-2 text-center">
                    D
                  </td>
                  <td className="border-[#3F3B3C] border-[1px] p-2 text-center">
                    E
                  </td>
                </tr>
              </tbody>
            </table>
            
            <div className="w-full flex justify-start items-center">
            {/* <div className="w-full max-w-lg p-4 mt-8 flex gap-2 text-xl justify-center rounded-lg border-blue-500 border-2 mb-4"> */}
              <div className="w-1/4 flex justify-end items-center">
                <div className="w-fit p-4 mt-8 flex gap-2 text-xl justify-center rounded-lg border-blue-500 border-2 mb-4">
                  {missingOptions.map((option) => (
                    // <div key={option} className="font-bold text-blue-500">
                    <div key={option} className=" text-3xl font-bold text-blue-500">
                      {option}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <div className="w-full mt-4 flex justify-between"> */}
            <div className="w-3/6 mt-4 flex justify-between">
              <Pilihan value={0} handleChange={handleChange} />
              <Pilihan value={1} handleChange={handleChange} />
              <Pilihan value={2} handleChange={handleChange} />
              <Pilihan value={3} handleChange={handleChange} />
              <Pilihan value={4} handleChange={handleChange} />
            </div>
          </form>
        </div>
      </div>

      {showLoadingModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="flex justify-center items-center bg-white w-60 h-24 rounded-lg">
                <div className="font-bold">LOADING...</div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default QuizPage;
